<template>
  <div class="se-card-box">
    <a-spin
      :spinning="loading"
      class="se-spin-stretch se-width-100p"
    >
      <a-descriptions :column="2">
        <a-descriptions-item label="终端设备名称">
          {{ data.full_name }}
        </a-descriptions-item>
        <a-descriptions-item label="所属客户">
          {{ data.customer_enterprise_name }}
        </a-descriptions-item>
        <a-descriptions-item label="所属集抄器">
          {{ data.gateway_full_name }}
        </a-descriptions-item>
        <a-descriptions-item label="客户账号">
          {{ data.customer_username }}
        </a-descriptions-item>
        <a-descriptions-item label="身份标识">
          {{ data.identity_no }}
        </a-descriptions-item>
        <a-descriptions-item label="客户联系人">
          {{ data.customer_full_name }}
        </a-descriptions-item>
        <a-descriptions-item label="入库时间">
          {{ data.created_at }}
        </a-descriptions-item>
      </a-descriptions>
    </a-spin>
  </div>
</template>

<script>
import { findTerminal } from '@/api/terminal'

export default {
  name: 'Info',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      loading: true,
      isShowEditModal: false
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    showEditModal() {
      this.isShowEditModal = true
    },

    fetchData() {
      this.loading = true
      findTerminal(this.id).then(res => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.se-card-box /deep/ .ant-descriptions-item-label {
  width: 100px;
  text-align: right;
}
</style>
