<template>
  <a-row>
    <a-row class="content">
      <info :id="id" />
      <a-button class="refresh" type="primary" @click="handleRefresh">
        刷新
      </a-button>
    </a-row>
    <div class="title">参数列表</div>
    <a-row class="content">
      <attribute-list :id="id" ref="attributeList" />
    </a-row>
  </a-row>
</template>

<script>
import Info from '@/views/terminals/details/modules/Info'
import AttributeList from '@/views/terminals/details/modules/AttributeList'

export default {
  name: 'Detail',
  components: {
    Info,
    AttributeList
  },
  computed: {
    id() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    handleRefresh() {
      this.$refs.attributeList.fetchData()
    }
  }
}
</script>

<style lang="less" scoped>
.refresh {
  position: absolute;
  right: 20px;
  top: 20px;
}

.title {
  margin: 10px;
}

.content {
  box-shadow: 0 0 10px #ccc;
  margin: 0 5px 5px 5px;
}
</style>
