import request from '@/utils/request'

// findTerminals 查找终端设备
export function findTerminals(params) {
  return request({
    url: `/admin/terminals`,
    method: 'get',
    params: params
  })
}

// findTerminals 查找终端设备详情信息
export function findTerminal(id) {
  return request({
    url: `/admin/terminals/${id}/details`,
    method: 'get'
  })
}
