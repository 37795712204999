import request from '@/utils/request'

// findAttributesList 获取集抄器设备参数列表数据
export function findAttributesList(params) {
  return request({
    url: `/attributes/list`,
    method: 'get',
    params: params
  })
}

// findAttributeHistoricalDataList 获取设备参数历史数据列表
export function findAttributeHistoricalDataList(params) {
  return request({
    url: `/attributes/historical`,
    method: 'get',
    params: params
  })
}

// findAttributeHistoricalDataThread 获取设备属性历史数据趋势
export function findAttributeHistoricalDataThread(params) {
  return request({
    url: `/attributes/thread`,
    method: 'get',
    params: params
  })
}
