<template>
  <a-pagination
    v-show="totalCount > 0"
    v-model="currentPage"
    :page-size-options="pageSizesOptions"
    :page-size.sync="pageSize"
    :show-total="total => `共 ${total} 条`"
    :total="totalCount"
    class="custom-pagination"
    show-quick-jumper
    show-size-changer
    @change="handlePageChange"
    @showSizeChange="handlePerPageChange"
  />
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    totalCount: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 10
    },
    pageSizesOptions: {
      type: Array,
      default() {
        return ['10', '20', '50']
      }
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.perPage
      },
      set(val) {
        this.$emit('update:perPage', val)
      }
    }
  },
  methods: {
    handlePageChange(page, perPage) {
      this.$emit('change', {
        page: page,
        per_page: perPage
      })
    },

    handlePerPageChange(page, perPage) {
      // ant design 会自动计算页数，还未同步到 page，主动同步
      this.$emit('update:page', page)
      this.$emit('change', {
        page: page,
        per_page: perPage
      })
    }
  }
}
</script>

<style lang="less" scoped>
.custom-pagination {
  margin: 16px 0;
  display: flex;
  justify-content: flex-end;

  /deep/ .ant-pagination-options-quick-jumper input {
    width: 80px;
  }
}
</style>
